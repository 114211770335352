/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
    if (!prevRouterProps || !prevRouterProps.location) return false;

    if (routerProps.location.pathname === prevRouterProps.location.pathname) {
        return false;
    }

    return true;
};

export { wrapPageElement } from "./src/components/WrapPageElement/wrapPageElement";
export { wrapRootElement } from "./src/components/WrapRootElement/wrapRootElement";
