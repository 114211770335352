exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-login-success-tsx": () => import("./../../../src/pages/login-success.tsx" /* webpackChunkName: "component---src-pages-login-success-tsx" */),
  "component---src-pages-logout-success-tsx": () => import("./../../../src/pages/logout-success.tsx" /* webpackChunkName: "component---src-pages-logout-success-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/Article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-basic-page-basic-page-tsx": () => import("./../../../src/templates/BasicPage/BasicPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-basic-page-tsx" */),
  "component---src-templates-ecp-ecp-tsx": () => import("./../../../src/templates/Ecp/Ecp.tsx" /* webpackChunkName: "component---src-templates-ecp-ecp-tsx" */),
  "component---src-templates-embedded-content-embedded-content-tsx": () => import("./../../../src/templates/EmbeddedContent/EmbeddedContent.tsx" /* webpackChunkName: "component---src-templates-embedded-content-embedded-content-tsx" */),
  "component---src-templates-external-publication-external-publication-tsx": () => import("./../../../src/templates/ExternalPublication/ExternalPublication.tsx" /* webpackChunkName: "component---src-templates-external-publication-external-publication-tsx" */),
  "component---src-templates-in-the-news-in-the-news-tsx": () => import("./../../../src/templates/InTheNews/InTheNews.tsx" /* webpackChunkName: "component---src-templates-in-the-news-in-the-news-tsx" */),
  "component---src-templates-landing-page-primary-landing-page-primary-tsx": () => import("./../../../src/templates/LandingPagePrimary/LandingPagePrimary.tsx" /* webpackChunkName: "component---src-templates-landing-page-primary-landing-page-primary-tsx" */),
  "component---src-templates-landing-page-secondary-tsx": () => import("./../../../src/templates/LandingPageSecondary.tsx" /* webpackChunkName: "component---src-templates-landing-page-secondary-tsx" */),
  "component---src-templates-networks-networks-tsx": () => import("./../../../src/templates/Networks/Networks.tsx" /* webpackChunkName: "component---src-templates-networks-networks-tsx" */),
  "component---src-templates-person-person-tsx": () => import("./../../../src/templates/Person/Person.tsx" /* webpackChunkName: "component---src-templates-person-person-tsx" */),
  "component---src-templates-project-project-tsx": () => import("./../../../src/templates/Project/Project.tsx" /* webpackChunkName: "component---src-templates-project-project-tsx" */),
  "component---src-templates-research-group-research-group-tsx": () => import("./../../../src/templates/ResearchGroup/ResearchGroup.tsx" /* webpackChunkName: "component---src-templates-research-group-research-group-tsx" */),
  "component---src-templates-series-series-tsx": () => import("./../../../src/templates/Series/Series.tsx" /* webpackChunkName: "component---src-templates-series-series-tsx" */),
  "component---src-templates-topic-region-topic-region-tsx": () => import("./../../../src/templates/TopicRegion/TopicRegion.tsx" /* webpackChunkName: "component---src-templates-topic-region-topic-region-tsx" */)
}

