export const getJoiningAuthorStr = (length = 0, index = 0): string =>
	length > 1 && index !== length - 1
		? index === length - 2
			? " and "
			: ", "
		: "";

export const getAuthorString = (authors: Authors): string => {
	let authorString = "";

	authors?.forEach((author, index) => {
		authorString += author?.field_full_name;
		authorString += getJoiningAuthorStr(authors.length, index);
	});

	return authorString;
};
export const tryParseJson = (json: string) => {
	try {
		return JSON.parse(json);
	} catch (e) {
		return json;
	}
};
