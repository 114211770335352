/**
 * Decrypts a ciphertext string using a public key.
 * @param publicKey - The public key to use for decryption.
 * @param ciphertext - The ciphertext to decrypt.
 * @returns - The decrypted plaintext.
 */
export const decrypt = (publicKey: string, ciphertext: string): string => {
    const encodedPublicKey = publicKey
        .split("")
        .reverse()
        .join("");

    let plaintext = "";
    for (let i = 0; i < ciphertext.length; i++) {
        const publicKeyCharCode = encodedPublicKey.charCodeAt(
            i % encodedPublicKey.length
        );
        const encryptedCharCode = ciphertext.charCodeAt(i);
        const decryptedCharCode = publicKeyCharCode ^ encryptedCharCode;
        plaintext += String.fromCharCode(decryptedCharCode);
    }

    return plaintext;
};