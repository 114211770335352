import React from "react";
import { AuthProvider } from "../../context/auth/AuthProvider";
import "../../style/theme.scss";
import { Fragments } from "../Fragments";

interface Props {
    element: string;
}

export const wrapRootElement: React.FC<Props> = ({ element }) => (
    <AuthProvider>
        <Fragments />
        {element}
    </AuthProvider>
);

export default wrapRootElement;
