import { graphql } from "gatsby";
import React from "react";

export const Fragments: React.FC = () => null;

export const fragment = graphql`
    fragment ImageKitFluid on ImageKitAssetFluid {
        aspectRatio
        base64
        images {
            fallback {
                src
                srcSet
                sizes
            }
            sources {
                srcSet
                sizes
                type
            }
        }
        layout
        width
        height
    }
    
    fragment ImageKitFixed on ImageKitAssetFixed {
        base64
        images {
            fallback {
                src
                srcSet
            }
            sources {
                srcSet
                type
            }
        }
        layout
        width
        height
    }
    
    fragment FieldMainContent on MainContentUnion {
        __typename
        ...AccordionParagraphData
        ...EmbedParagraphData
        ...ImageParagraphData
        ...PullQuoteParagraphData
        ...SignpostParagraphData
        ...TextParagraphData
        ...VideoEmbedParagraphData
    }

    fragment FieldSecondaryContent on SecondaryContentUnion {
        __typename
        ...ProfileReferencesData
        ...IconLinksData
        ...SingleSignpostData
    }
    fragment EncryptedNode on encrypted_node {
        __typename
        isEncrypted
        content
    }
    fragment FieldSectionsBase on Sections {
        __typename
        ...AccordionParagraphData
        ...AgendaParagraphData
        ...ButtonLabelLinksParagraphData
        ...EmbedParagraphData
        ...ImageParagraphData
        ...IndentedContentParagraphData
        ...InSectionSearch
        ...LockedDownloadParagraphData
        ...NewsletterSignup
        ...ParagraphSignpostGrid
        ...PullQuoteParagraphData
        ...RelatedProjectsParagraphData
        ...ParagraphRSSFeed
        ...SignpostParagraphData
        ...SignpostsWithSubSectionLinks
        ...SpeakerContainer
        ...SponsorsParagraphData
        ...TabsContainer
        ...TextParagraphData
        ...UpcomingEventParagraphData
        ...UpcomingEventsData
        ...VideoEmbedParagraphData
        ...AboutBlockData
        ...BrandSubstantiationBlockData
        ...MemberPromoCtaBlockParagraphData
        ...HeroArticleData
        ...ParagraphIframeGrid
        ...EncryptedNode
    }

    fragment FieldSections on Sections {
        ...FieldSectionsBase
        ...LatestNewsParagraphData
        ...LatestPublicationsData
    }

    fragment FieldSectionsPerson on Sections {
        ...FieldSectionsBase
        ...LatestNewsByAuthor
        ...LatestPublicationsData
        # ...LatestPublicationsByAuthor
    }

    fragment FieldSectionsTopicRegion on Sections {
        ...FieldSectionsBase
        ...LatestNewsByTopic
        ...LatestPublicationsData
        # ...LatestPublicationsByTopic
    }

    fragment FieldSectionsNetworks on Sections {
        ...FieldSectionsBase
        ...LatestNewsByNetworks
        ...LatestPublicationsData
        # ...LatestPublicationsByNetworks
    }

    fragment FieldFootnote on node__notes {
        __typename
        id
        drupal_id
        title
        body {
            processed
        }
    }
`;
