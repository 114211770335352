import { PageProps } from "gatsby";
import React from "react";

interface WrapPageElementProps {
	element: string;
	props: PageProps;
}

export const wrapPageElement: React.FC<WrapPageElementProps> = ({
	element,
	props,
}) => <>{element}</>;
